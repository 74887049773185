
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { AddressService } from '@/services/address-service';
import { CustomerPaymentModel } from '@/store/models/PaymentModel';
import { BankAccountTypes, ConstValues } from '@/ConstValues';
import { DisableFieldAutofill } from '@/helpers/WebHelper';
import { PaymentService } from '@/services/payment-service';
import { ConversionHelper } from '@/helpers/ConversionHelper';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { Getter } from 'vuex-class';
import { Customer } from '@/store/models/CustomerModel';
import { Clone } from '@/helpers/ObjectHelper';
import { Address } from '@/store/models/AddressModel';
import StripePaymentComponent from './StripePaymentComponent.vue';
@Component({ components: { StripePaymentComponent } })
export default class PaymentAdd extends Vue {
  $refs!: {
    Form: HTMLFormElement;
    StripeCard: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  @Getter('getCustomer', { namespace: 'customer' }) Customer: Customer;
  /* Watchers */
  @Watch('newPaymentModel.ExpirationDate', { deep: true })
  onDateChanged() {
    this.dateFormatted = this.formatDate(this.newPaymentModel.ExpirationDate);
  }
  /* Data */
  shouldOpenNewPaymentDialog: boolean = false;
  isNewPaymentFormValid: boolean = false;
  isSameAsPrimaryAddress: boolean = false;
  isLoading: boolean = false;
  isAddingPaymentMethod: boolean = false;
  hasNotFoundPaymentProfile: boolean = false;
  newPaymentModel: CustomerPaymentModel = new CustomerPaymentModel();
  newPaymentException: string = '';
  billingAddresses: any[] = [];
  states: { id: string; name: string }[] = [];
  countries: any[] = [];
  expiryDateMenu: boolean = false;
  dateFormatted: string = '';
  paymentTypes: Array<{ Name: string; type: string }> = [
    { Name: 'Credit Card', type: 'Card' }
    // { Name: 'ACH Transfer', type: 'Bank Account' }
  ];
  /* Methods */
  get bankAccountTypes() {
    return BankAccountTypes;
  }
  openNewPaymentDialog() {
    this.GetBillingAddressesCondensed();
    this.GetStates();
    this.GetCountries();
    this.shouldOpenNewPaymentDialog = true;
    DisableFieldAutofill('state');
    DisableFieldAutofill('country');
  }
  closeNewPaymentDialog() {
    this.newPaymentModel = new CustomerPaymentModel();
    this.shouldOpenNewPaymentDialog = false;
  }
  async saveNewPaymentMethod() {
    this.isLoading = true;
    try {
      this.newPaymentModel.IsBankAccount = this.newPaymentModel.IsABankAccount();
      if (!this.newPaymentModel.IsBankAccount) {
        this.newPaymentModel.Address = ConversionHelper.convertAddressToAddressModel(
          this.newPaymentModel.AddressToEdit,
          true,
          true,
          false
        );
        this.newPaymentModel.ExpirationDate = this.dateFormatted;
      }

      const response = await PaymentService.SaveNewPaymentMethod(
        this.newPaymentModel.ConvertToRequestModel()
      );
      if (response.data) {
        this.newPaymentException = response.data;
        this.isLoading = false;
        return;
      }
      this.closeNewPaymentDialog();
      this.$emit('paymentAdded');
      SetSnackBar('Added new payment successfully');
    } catch (err) {
      SetSnackBar(
        'There was an error adding your payment. Please contact techsupport@just1label.com'
      );
    }
    this.isLoading = false;
  }
  async saveStripePaymentMethod() {
    this.isAddingPaymentMethod = true;
    try {
      await this.$refs.StripeCard.submitCard();
    } catch (error) {
      SetSnackBar(
        'There was an error adding your payment. Please contact techsupport@just1label.com or try again later'
      );
    }
    this.isAddingPaymentMethod = false;
  }

  formatDate(date: string) {
    if (!date) return '';

    if (date.includes('-')) {
      const [year, month] = date.split('-');
      const [century, yearFormatted] = year.split('20');
      return `${month}/${yearFormatted}`;
    }
    const [month, year] = date.split('/');
    console.log(`${month}/${year}`);
    return `${month}/${year}`;
  }
  parseDate(date: string) {
    if (!date) return '';

    const [month, year] = date.split('/');
    return `20${year}-${month}`;
  }
  isValidDate(date: string) {
    const [month, year] = date.split('/');
    const todaysDate = new Date();
    if (`20${year}` === todaysDate.getFullYear().toString())
      return parseInt(month) >= todaysDate.getMonth() + 1;
    if (`20${year}` > todaysDate.getFullYear().toString()) return true;
    return false;
  }
  updateBillingAddressFromPrimary() {
    if (this.isSameAsPrimaryAddress) {
      this.newPaymentModel.AddressToEdit = Clone(this.Customer.BillingAddress);
      let stateValue = this.states.find(
        s => s.id == this.newPaymentModel.AddressToEdit.State
      );
      if (stateValue) this.newPaymentModel.AddressToEdit.State = stateValue.name;
      this.$nextTick();
    } else {
      this.newPaymentModel.AddressToEdit = new Address();
    }
  }
  /* Computed */
  get ConstValues() {
    return ConstValues;
  }
  get ccvMaxLength() {
    if (this.newPaymentModel.CreditCardNumber) {
      let regex = new RegExp('^3[4,7]\\d{13}$', 'g');
      let isAmex = regex.test(this.newPaymentModel.CreditCardNumber);
      if (isAmex) return 4;
    }
    return 3;
  }
  /* Loaders */
  async GetBillingAddressesCondensed(addressId: string = '') {
    const response = await AddressService.GetBillingAddresses();
    this.billingAddresses = response.data;
    if (addressId) this.newPaymentModel.BillingAddressId = addressId;
  }
  async GetStates() {
    const response = await AddressService.GetStates();
    this.states = Object.keys(response.data).map(key => ({
      id: key,
      name: response.data[key]
    }));
  }
  async GetCountries() {
    const response = await AddressService.GetCountries();
    this.countries = response.data;
    if (this.newPaymentModel.AddressToEdit.Country.Id === 0) {
      this.newPaymentModel.AddressToEdit.Country = this.countries[0];
    }
  }
  /* Mounted */
  mounted() {
    this.newPaymentModel.PaymentType = this.paymentTypes[0].type;
  }
  /* Created */
}
